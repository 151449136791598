












































import { Notify } from "vant"
import Stroage from "@/util/Storage"
import { HelpTypeT } from "@/Type/index"
import { UserType } from "@/Api/Basics/InterfaceType"
import { HelpStore } from "@/store/modules/Help/Help"
import { HelpTypeList } from "@/Api/Help/index"
import { Vue,Component,Watch,Emit } from "vue-property-decorator"

interface IssueNavType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    RightIcon:T;
    NavList:Array<HelpTypeT>
    NavIndex :number
    Show:boolean
    UserInfo:UserType

    initData():void;
    handleChangeIndex(data:any,index:number):void;
    handleUpData():void;
    handleError(data:string):void;
}

@Component({ name:"IssueNav" })
export default class IssueNav extends Vue implements IssueNavType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    RightIcon = require("$icon/Basics/Right.png");
    NavList:Array<HelpTypeT> = [ ]
    NavIndex = 0
    Show = false
    UserInfo:UserType = {}

    get GetFilterList(){
        if( this.NavList.length > 1 ){
            return this.NavList.slice(0,3)
        }else{
            return this.NavList
        }
    }

    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        try{
            this.UserInfo = JSON.parse( Stroage.GetData_ && Stroage.GetData_("UserData") ); 
        }catch(cat){
            new Error(cat)
        }

        this.initData()
    }

    initData(){
        this.NavIndex = HelpStore.GetHelpIUpData.NavIndex
        if( HelpStore.GetHelpITL.length ){
            this.NavList = HelpStore.GetHelpITL
            this.PullData( this.NavList[ this.NavIndex ] )
        }else{
            this.handleUpData()
        }
    }

    handleToggleShow(){
        this.Show = !this.Show
    }

    handleChangeIndex(data:any,index:number){
        if( this.NavIndex != index ){
            this.NavIndex = index
            this.PullData( data )
        }
    }

    handleUpData(){
        this.loadingShow = true
        HelpTypeList({
            userId:this.userId,
            token:this.token
        },{school:this.UserInfo.school as string}).then(res=>{
            this.loadingShow = false
            if( res.message.code === "200" ){
                this.NavList = res.data
                HelpStore.SetHelpITL( res.data )
                this.PullData( this.NavList[ this.NavIndex ] )
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }

    @Watch("Show")
    handleChangeShow(newVal:boolean){
        if( newVal ){
            document.body.style.overflow = "hidden"   
        }else{
            document.body.style.overflow = "auto"   
        }
    }

    @Emit("PullData")
    PullData(data:any){
        return data
    }
}
