




































import { Notify,Toast } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import IssueNav from "@/components/HelpComponent/Issue/IssueNav.vue"
import IssueCont from "@/components/HelpComponent/Issue/IssueCont.vue"
import IssueMoney from "@/components/HelpComponent/Issue/IssueMoney.vue"
import IssueAddress from "@/components/HelpComponent/Issue/IssueAddress.vue"
import Stroage from "@/util/Storage"
import { IssueUpData } from "@/Api/Help/interfaceType"
import { AddressType } from "@/Type/index"
import { HelpStore } from "@/store/modules/Help/Help"
import { IssueHelp as IssueUpHelp,HelpBefore,HelpAfter } from "@/Api/Help/index"
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";
import {setPullData} from "@/util/compatibility_App";

// let WeixinJSBridge:any = ( "WeixinJSBridge" in window  ) ? window.WeixinJSBridge : {}; // {}//
declare let WeixinJSBridge: any;
declare const document:any;

interface IssueHelpType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    UpData:IssueUpData
    OrderId:T;
    OrderData:any

    handleGetNavData(data:any):void;
    handleGetContent(data:string):void;
    handleGetImg(data:Array<string>):void;
    handleGetMoney(data:T):void;
    handleGetAddress(data:AddressType):void;
    handleUpClick():void;
    handlePay():void;
    onBridgeReady():void;
    handleCallBack():void;
    handleClear():void;
    handleError(data:string):void;
}

@Component({ name:"IssueHelp",components:{ IssueNav,IssueCont,IssueMoney,IssueAddress,HeadTop } })
export default class IssueHelp extends ZoomPage implements IssueHelpType{
    loadingShow = true
    userId:string|number = ""
    token = ""
    UpData:IssueUpData = {
       title:"",
       typeName:"",
       typeCode:"",
       content:"",
       price:"0.01",
       picture:"",
       destAddress:"",
       destAddressName:"",
       destAddressPhone:"",
    }
    OrderId = ""
    OrderData:any = {}

   mounted() {
       this.loadingShow = false
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
   }

   handleGetNavData(data: any): void {
       this.UpData.title = data.name as string
       this.UpData.typeName = data.name as string
       this.UpData.typeCode = data.code as string
   }

   handleGetContent(data:string){
       this.UpData.content = data as string
   }

   handleGetImg(data:Array<string>){
       if( data.length ){
           let Str = ""
           data.forEach((item,index)=>{
               if( index ){
                   Str += ","+item
               }else{
                   Str += item
               }
           })
           this.UpData.picture = Str as string
       }else{
           this.UpData.picture = ""
       }
   }

   handleGetMoney(data:string){
       this.UpData.price = data as string
   }

   handleGetAddress(data:AddressType){
       if( JSON.stringify( data ) != "{}" ){
           this.UpData.destAddress = data.address
           this.UpData.destAddressName = data.name
           this.UpData.destAddressPhone = data.phone as string
       }else{
           this.UpData.destAddress = ""
           this.UpData.destAddressName = ""
           this.UpData.destAddressPhone = ""
       }
   }

   handleUpClick(){
       let { UpData } = this
       if( !UpData.content?.length ){
           this.handleError("请输入发布的内容")
           return;
       }
       this.UpData.price = this.UpData.price?.replace(/ /g,"")
       if( this.UpData.price!.length <= 0 ){
           this.handleError("请输入发布金额，最小0.01元")
           return;
       }
       if( Number( this.UpData.price ) <= 0 ){
           this.handleError("请输入发布金额，最小0.01元")
           return;
       }
       this.loadingShow = true
       new Promise( (reslove,reject)=>{
           IssueUpHelp({
               userId:this.userId,
               token:this.token
           },UpData).then(res=>{
               console.log( res )
               if( res.message.code === "200" ){
                   this.OrderId = res.data as string
                   reslove(res.data as string)
               }else{
                   reject(res)
               }
           })
       } ).then(res=>{
           let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
           if ( bool != null && bool[0] != "micromessenger" ){
               this.loadingShow = false
               this.$router.push({
                   path:"/goPay",
                   query:{
                       orderId:this.OrderId,
                       state:"help",
                       money:this.UpData.price + '',
                       price:'0'
                   }
               })
               this.handleClear()
               // setPullData({ orderId:res })
           }else{
               HelpBefore({
                   userId:this.userId,
                   token:this.token
               },{orderId:res as string}).then( res=>{
                   if( res.message.code === "200" ){
                       this.handlePay()
                   }else{
                       this.loadingShow = false
                       this.handleError( res.message.msg )
                   }
               } )
           }
       }).catch(cat=>{
           this.loadingShow = false
           this.handleError( cat.message.msg )
       })
   }

   handlePay(){
       if ( "WeixinJSBridge" in window  && typeof WeixinJSBridge == "undefined"){
           if( document.addEventListener ){
               document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
           }else if ( "attachEvent" in document ){
               document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
               document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
           }
       }else{
           this.onBridgeReady();
       }
   }

   onBridgeReady(){
       ( "invoke" in WeixinJSBridge) && WeixinJSBridge.invoke(
           'getBrandWCPayRequest', {
               "appId":this.OrderData.appId,//公众号名称，由商户传入
               "timeStamp":this.OrderData.timeStamp,//时间戳，自1970年以来的秒数
               "nonceStr":this.OrderData.nonceStr, //随机串
               "package":this.OrderData.package,
               "signType":this.OrderData.signType,//微信签名方式：
               "paySign":this.OrderData.paySign //微信签名
           },
           (res:any)=>{
               if(res.err_msg == "get_brand_wcpay_request:ok" ){
                   this.handleCallBack()
               }
           });
   }

   handleCallBack(){
       HelpAfter({
           userId:this.userId,
           token:this.token
       },{
           orderId:this.OrderId
       }).then( res=>{
           console.log( res )
           this.loadingShow = false
           if( res.message.code === "200" ){
           Toast({
               message:"支付成功",
               icon:"none",
               className:"ToastClassName"
           })

           let time = setTimeout( ()=>{
               this.$router.go(-1)
               clearTimeout(time)
           },1000 )
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleClear(){
       HelpStore.SetHelpIUpData({
           NavIndex:0,
           content:"",
           ImgList:[],
           Money:0,
           IsAddress:true,
       })
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

}
