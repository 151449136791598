





















































import { Notify } from "vant"
import { ImagePreview } from 'vant';
import Stroage from "@/util/Storage"
import { HelpStore } from "@/store/modules/Help/Help"
import { UpPhotos } from "@/Api/UpPhoto/index"
import { Vue,Component, Watch, Emit } from "vue-property-decorator"

interface IssueContType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    Value:T;
    TestIcon:T;
    AddIcon:T;
    DelIcon:T;
    ImgList:Array<string>

    initData():void;
    handlePreviewImg(index:number):void;
    handleRemoveImg(index:number):void;
    handleUpImg(e:any):void;
    handleConcatList( data:Array<string> ):void;
    handleSetImg():void;
    handleSetContent():void;
    handleError(data:string):void;
}

@Component({ name:"IssueCont" })
export default class IssueCont extends Vue implements IssueContType{
    loadingShow = false
    loadingText = "正在上传图片中..."
    userId:string|number = ""
    token = ""
    Value = ""
    TestIcon = require("$icon/Shop/TestShop.png")
    AddIcon = require("$icon/Basics/Add.png");
    DelIcon = require("$icon/Help/Del.png")
    ImgList:Array<string> = []

    mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")  
       this.initData() 
    }

    initData(){
        this.ImgList = HelpStore.GetHelpIUpData.ImgList as string[]
        this.Value = HelpStore.GetHelpIUpData.content as string
    }

    handlePreviewImg(index:number){
        ImagePreview({
           images:this.ImgList,
           startPosition: index,
       });
    }

    handleRemoveImg(index:number){
        let List = this.ImgList
        List.splice(index,1)
        this.ImgList = List
    }

    handleUpImg(e:any){
        this.loadingShow = true
        this.loadingText = "正在上传图片中..."
        let UpImg:Array<any> = []
        if( Array.isArray( e ) ){
            UpImg = e;
        }else{
            UpImg = [ e ]
        }
        let formData:any = new FormData()
        UpImg.forEach( item=>{
            formData.append("files", item.file )
        } )
        formData.append("functionName","other")
        console.log( UpImg,formData )
        UpPhotos({
            userId:this.userId,
            token:this.token
        },formData).then( res=>{
            this.loadingShow = false
            console.log( res )
            if( res.message.code === "200" ){
                this.handleConcatList( res.data )
            }else{
                this.loadingText = "上传失败"
                this.handleError( res.message.msg )
            }
        } )
    }

    handleConcatList( data:Array<string> ){
        let Num = 8 - this.ImgList.length
        if( Num ){
            let List:Array<string> = data.slice( 0,Num );
            this.ImgList = this.ImgList.concat( List as Array<string> )
        }
    }

    handleSetImg(){
        let data = HelpStore.GetHelpIUpData
        data.ImgList = this.ImgList
        HelpStore.SetHelpIUpData( data )
    }

    handleSetContent(){
        let data = HelpStore.GetHelpIUpData
        data.content = this.Value
        HelpStore.SetHelpIUpData( data )
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }

    @Watch("ImgList",{ deep:true })
    ChangeImgList(newVal:Array<string>){
        this.PullImgList( newVal )
        this.handleSetImg()
    }

    @Emit("PullImgList")
    PullImgList(data:Array<string>){
        return data
    }

    @Watch("Value")
    ChangeValue(newVal:string){
        this.PullCont( newVal )
        this.handleSetContent()
    }

    @Emit("PullCont")
    PullCont(data:string){
        return data
    }
}
