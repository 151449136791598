








































import { Notify,Dialog } from "vant"
import Stroage from "@/util/Storage"
import { AddressType } from "@/Type/index"
import { HelpStore } from "@/store/modules/Help/Help"
import { UserStore } from "@/store/modules/User"
import { GetDefaultAddress } from "@/Api/Basics/index"
import { Vue,Component, Emit, Watch } from "vue-property-decorator"

interface IssueAddressType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    RightIcon:T;
    AddIcon:T;
    Show:boolean
    AddressData:AddressType
    AddressBool:boolean

    initData():void;
    handleChangeClick():void;
    handleError(data:string):void;
}

@Component({ name:"IssueAddress" })
export default class IssueAddress extends Vue implements IssueAddressType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    RightIcon = require("$icon/Basics/Right.png");
    AddIcon = require("$icon/Basics/location.png");
    Show = true
    AddressData:AddressType = {}
    AddressBool = false

    mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")  
       this.initData()
   }

    initData(){
        this.Show = HelpStore.GetHelpIUpData.IsAddress
        if( JSON.stringify( UserStore.GetSelAddress ) != "{}" ){
            this.AddressBool = true
            this.AddressData = UserStore.GetSelAddress
        }else{
            this.handleGetAddress()
        }
    }

    handleChangeClick(){
        this.Show = !this.Show
    }

    handleSelAddress(){
       this.$router.push({
           path:"/addressList",
           query:{
               select:"true"
           }
       })
   }

   handleGetAddress(): void {
       this.loadingShow = true
       GetDefaultAddress({
           userId:this.userId,
           token:this.token
       }).then( (res:any)=>{
           this.loadingShow = false
           if ( res.message.code === "200" ) {
               if ( res.data ) {
                   this.AddressData = res.data
                   this.AddressBool = true
                   UserStore.SetSelAddress( res.data )
                   this.PullAddress( res.data )
               }else{
                   this.AddressBool = false
                   Dialog.confirm({
                       title: '温馨提醒',
                       message: '请添加或选择地址',
                       className:"HideClassName",
                       overlayClass:"MaskHideClassName"
                   }).then(()=>{
                       this.$router.push({
                           path:"/addressList",
                           query:{
                               select:"true"
                           }
                       })
                   }).catch(()=>{
                       //
                   })
               }
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }

   @Watch("Show")
   ChangeShow(newVal:boolean){
       if( !this.Show ){
           this.PullAddress( {} )
       }else{
           this.PullAddress( this.AddressData )
       }
       let time = setTimeout(()=>{
           let data = HelpStore.GetHelpIUpData
           data.IsAddress = newVal
           HelpStore.SetHelpIUpData( data )
           clearTimeout( time )
       })
   }

   @Emit("PullAddress")
   PullAddress(data:AddressType){
       return data
   }
}
