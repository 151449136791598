





















import { Vue,Component,Emit, Watch } from "vue-property-decorator"

interface IssueMoneyType<T=string>{
    List:Array<{ text:T }>
    Index:number
    Value:T;
}

@Component({ name:"IssueMoney" })
export default class IssueMoney extends Vue implements IssueMoneyType{
    List = [
        { text:"0.01" },
        { text:"0.50" },
        { text:"1.00" },
        { text:"2.00" },
        { text:"5.00" },
        { text:"8.00" },
        { text:"10.00" },
        { text:"15.00" },
    ]
    Index = 0
    Value = ""
    

    mounted() {
       this.PullMoney( this.List[ this.Index ].text )
    }

    handleChangeClick(index:number){
        this.Index = index
    }

    @Watch("Index")
    ChangeIndex(newVal:number){
        if( newVal == this.List.length ){
            this.PullMoney( this.Value )
        }else{
            this.PullMoney( this.List[ newVal ].text )
        }
    }

    @Watch("Value")
    ChangeValue(newVal:string){
        if( this.Index == this.List.length ){
            this.PullMoney( newVal )
        }
    }

    @Emit("PullMoney")
    PullMoney(data:any){
        return data
    }
}
